import { ModalWhatsAppComponent } from "./../../../core/_components/modal-whats-app/modal-whats-app.component";
import { environment } from "./../../../../environments/environment";
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../../core/_services/authentication.service";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Subscription } from "rxjs";
import firebase from "firebase/app";
import "firebase/auth";
import { MatDialog } from "@angular/material/dialog";
import { VilleComponent } from "src/app/core/_components/ville/ville.component";
import { CustomService } from "src/app/core/_services/custom.service";

declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  private subscription: Subscription;
  title: string = "components.ask_connect_account";
  private returnUrl: string;
  is_register_form = false;
  is_login_form = false;
  is_forget_form = false;
  object_login: any = {};
  object_register: any = {};
  private phone: string;
  windowRef: any;
  verificationCode: string;
  current_user: any
  codeAleatoire: any = Math.floor(Math.random() * 9000) + 1000 + '';

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Cameroon,
    // CountryISO.France,
    // CountryISO.Mali,
    // CountryISO.Niger,
    // CountryISO.CôteDIvoire,
    // CountryISO.Senegal,
    // CountryISO.Gabon,
  ];

  type_login: string = "phone";

  phoneForm = this.fb.group({
    phone_number: [undefined, [Validators.required, this.mtnCameroun]],
    code: ["", [Validators.required]],
  });
  get phoneNumberP() {
    return this.phoneForm.get("phone_number");
  }

  registerForm = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
    phone_number: [undefined],
    player_name: ["", [Validators.required, Validators.minLength(4)]],
    full_name: ["", [Validators.required, Validators.minLength(4)]],
    is_whatsapp: [false],
  });

  get phoneNumberR() {
    return this.registerForm.get("phone_number");
  }

  get emailR() {
    return this.registerForm.get("email");
  }

  get playerName() {
    return this.registerForm.get("player_name");
  }

  get fullName() {
    return this.registerForm.get("full_name");
  }

  loginForm = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
    code: ["", [Validators.required, Validators.minLength(4)]],
  });

  get phoneNumberL() {
    return this.loginForm.get("phone_number");
  }

  get code() {
    return this.loginForm.get("code");
  }

  number_change(phone: AbstractControl, length: number = 9) {
    if (phone.value) {
      const val = phone.value.toString();
      if (val.length > length) {
        phone.patchValue(+val.slice(0, -1));
      }
    }
  }

  mtnCameroun(control: AbstractControl): ValidationErrors | null {
    const numero = control.value?.e164Number;
    // alert(JSON.stringify(numero))
    const regexCameroun = /^(\+?237|00237)?[2368]\d{7,8}$/;

    if (!regexCameroun.test(numero)) {
      return { 'formatCameroun': true };
    }

    const plagesMTN = [
      '650', '651', '652', '653', '654',
      '670', '671', '672', '673', '674', '675', '676', '677', '678', '679',
      '680', '681', '682', '683', '684', '685', '686', '687', '688', '689'
    ];

    const indicatif = numero.slice(-9, -6);
    if (!plagesMTN.includes(indicatif)) {
      // alert(JSON.stringify(numero))
      // this.custom_api.logOrange(numero).subscribe(res =>{console.log(res)})
      return { 'mtnCameroun': true };
    }
    return null;
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    public _auth: AuthenticationService,
    public dialog: MatDialog,
    private custom_api: CustomService
  ) {}

  ngOnInit(): void {
    // this.openInput()
    this.windowRef = this._auth.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );

    this.windowRef.recaptchaVerifier.render();
    console.log(this.windowRef.recaptchaVerifier);

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/accueil";
  }

  openDialog() {
    this.dialog.open(ModalWhatsAppComponent, {
      width: "90%",
      minWidth: "90%",
      panelClass: "dialog-wha",
      data: { key: "login_wha" },
    });
  }

  openInput(){
    this.dialog.open(VilleComponent, {
      width: "90%",
      minWidth: "80%",
      panelClass: "dialog-wha",
      hasBackdrop: false,
      data: { key: "login_wha" },
    });
  }

  ngAfterViewInit(): void {
    this.__init();
    //this.openDialog();
  }

  __init() {
    this.object_login = {};
    this.object_register = {};
    this.phone = null;
    this.loginForm.reset();
    this.registerForm.reset();
    this.phoneForm.reset();
    this.windowRef.confirmationResult = null;

    setTimeout(() => {
      this._auth.api.active_input();
    }, 230);
  }

  login() {
    if (environment.production) {
      this.subscription = this.recaptchaV3Service.execute("login").subscribe(
        (token) => {
          console.log(token);
          this.loginForm.get("recaptcha").patchValue(token);
          this.handle_login();
        },
        (error) => {
          this._auth.api.toast.error(error);
        }
      );
    } else {
      console.log(this.loginForm.value);

      //this.handle_login();
    }
  }

  handle_login() {
    const country = this._auth.api.list_country.find(
      (it) => it.alpha2 === this.phoneNumberL.value.countryCode.toLowerCase()
    );
    console.log(country);
    if (country) {
      const log = this.loginForm.value;

      if (this.loginForm.valid) {
        this._auth.api.is_loader(true);
        this.object_register.ask_resend_link = false;
        this._auth
          .login({
            phone: log.phone_number.e164Number,
            code: log.code,
            recaptcha: log.recaptcha,
            countryCode: log.phone_number.countryCode.toLowerCase(),
            dialCode: log.phone_number.dialCode,
            ref_parent: localStorage.getItem("key_parain_code")
          })
          .subscribe(
            (data) => {
              if (data.message) {
                this._auth.api.toast.success(data.message);
              }
              this.router.navigate([this.returnUrl]).then(
                () => {
                  this._auth.api.is_loader(false);
                },
                () => {
                  this._auth.api.is_loader(false);
                }
              );
              this.loginForm.reset();
              this.__init();
            },
            (err) => {
              this._auth.api.is_loader(false);

              if (err.error.ask_resend_link && err.error.message) {
                this._auth.api.toast.info(err.error.message);
                this.object_login.ask_resend_link = true;
                this.phone = log.phone_number;
              } else if (err.error.message) {
                this._auth.api.toast.error(err.error.message);
              }
              console.log(err);
            }
          );
      } else if (this._auth.api.current_lang) {
        if (this._auth.api.current_lang.components) {
          this._auth.api.toast.info(
            this._auth.api.current_lang.components.check_required_message
          );
        }
      }
    } else {
      if (
        this._auth.api.current_lang &&
        this._auth.api.current_lang.components
      ) {
        this._auth.api.toast.info(
          this._auth.api.current_lang.components.country_not_exist
        );
      }
    }
  }

  doFacebookLogin() {
    this._auth.api.is_loader(true);
    this._auth.doFacebookLogin().then(
      (user) => {
        this.object_register.ask_resend_link = false;
        this._auth
          .login({
            ...user.providerData[0],
            uid: user.uid,
            ref_parent: localStorage.getItem("key_parain_code")
          })
          .subscribe(
            (data) => {
              if (data.message) {
                this._auth.api.toast.success(data.message);
              }
              this.router.navigate([this.returnUrl]).then(
                () => {
                  this._auth.api.is_loader(false);
                },
                () => {
                  this._auth.api.is_loader(false);
                }
              );
              this.loginForm.reset();
              this.__init();
            },
            (err) => {
              this._auth.api.is_loader(false);

              if (err.error.ask_resend_link && err.error.message) {
                this._auth.api.toast.info(err.error.message);
                this.object_login.ask_resend_link = true;
              } else if (err.error.message) {
                this._auth.api.toast.error(err.error.message);
              }
              console.log(err);
            }
          );
      },
      (err) => {
        this._auth.api.is_loader(false);
        if (err.code == "auth/account-exists-with-different-credential")
          this._auth.api.toast.info(
            this._auth.api.current_lang.components.connect_google_account
          );
      }
    );
  }

  doGoogleLogin() {
    this._auth.api.is_loader(true);
    this._auth.doGoogleLogin().then(
      (user) => {
        this.object_register.ask_resend_link = false;
        this._auth
          .login({
            ...user.providerData[0],
            uid: user.uid,
            ref_parent: localStorage.getItem("key_parain_code"),
          })
          .subscribe(
            (data) => {
              if (data.message) {
                this._auth.api.toast.success(data.message);
              }
              this.router.navigate([this.returnUrl]).then(
                () => {
                  this._auth.api.is_loader(false);
                },
                () => {
                  this._auth.api.is_loader(false);
                }
              );
              this.loginForm.reset();
              this.__init();
            },
            (err) => {
              this._auth.api.is_loader(false);

              if (err.error.ask_resend_link && err.error.message) {
                this._auth.api.toast.info(err.error.message);
                this.object_login.ask_resend_link = true;
              } else if (err.error.message) {
                this._auth.api.toast.error(err.error.message);
              }
            }
          );
      },
      (err) => {
        this._auth.api.is_loader(false);
        if (err.code == "auth/account-exists-with-different-credential")
          this._auth.api.toast.info(
            this._auth.api.current_lang.components.connect_facebook_account
          );
      }
    );
  }

  // sendLoginCode() {
  //   const tel = this.phoneNumberP.value.e164Number;
  //   const IsMtn = this.estNumeroMTNCameroun(tel)
  //   if(!IsMtn){
  //     // alert("Désolé ! ☹ Ton numéro de téléphone n'est pas éligible. Rejoins le Réseau Smart pour tenter ta chance !")
  //     this.custom_api.logOrange(tel).subscribe()
  //   }
  //   else{
  //     if (this.phoneNumberP.valid) {
  //       this._auth.api.is_loader(true);
  //       const appVerifier = this.windowRef.recaptchaVerifier;
  //       console.log(this.windowRef.recaptchaVerifier)
  //       const num = this.phoneNumberP.value.e164Number;
  //       console.log(this.phoneForm.value);

  //       this._auth.afAuth.signInWithPhoneNumber(num, appVerifier).then(
  //         (result) => {
  //           this._auth.api.is_loader(false);
  //           this.windowRef.confirmationResult = result;
  //         },
  //         (error) => {
  //           this._auth.api.is_loader(false);
  //           this._auth.api.toast.error(
  //             this._auth.api.current_lang.components.fail_send_login_code_sms
  //           );
  //         }
  //       );
  //     } else {
  //       this._auth.api.toast.info(
  //         this._auth.api.current_lang.components.phone_not_valid
  //       );
  //     }
  // }
  // }

  sendLoginCode() {
    const tel = this.phoneNumberP.value.e164Number;
    const IsMtn = this.estNumeroMTNCameroun(tel)
    if(!IsMtn){
      // alert("Désolé ! ☹ Ton numéro de téléphone n'est pas éligible. Rejoins le Réseau Smart pour tenter ta chance !")
      this.custom_api.logOrange(tel).subscribe()
    }
    else{
      if (this.phoneNumberP.valid) {
        this._auth.api.is_loader(true);


        const appVerifier = this.windowRef.recaptchaVerifier;
        console.log(this.windowRef.recaptchaVerifier)
        const num = this.phoneNumberP.value.e164Number;
        console.log(this.phoneForm.value);


        this.custom_api.sendCodeOTP(num, this.codeAleatoire).subscribe({
          next: (data: any) => {
            this._auth.api.is_loader(false);
            this.windowRef.confirmationResult = data;
          },
          error: (error) => {
            // Gère les erreurs
            console.error('Une erreur est survenue :', error);
          },
          complete: () => {
            // Gère la fin de l'observable
            console.log('Observable terminé');
          }});

      } else {
        this._auth.api.toast.info(
          this._auth.api.current_lang.components.phone_not_valid
        );
      }
  }
  }

  verifyLoginCode() {
    if(this.codeAleatoire === this.phoneForm.value.code) {
    this._auth.api.is_loader(true);
    this.custom_api.getTokenNumber(this.phoneNumberP.value.e164Number).subscribe({
      next: (data: any) => {
        this._auth
        .login({
          displayName: null,
          "photo URL": null,
          email: null,
          phoneNumber: this.phoneNumberP.value.e164Number,
          providerId: "phone",
          uid: data.uid,
          ref_parent: localStorage.getItem("key_parain_code"),
        })
        .subscribe(
          (data) => {
            this.current_user = this._auth.currentUserValue
            if (data.message) {
              this._auth.api.toast.success(data.message);
            }
            this.router.navigate([this.returnUrl]).then(
              () => {
                this._auth.api.is_loader(false);
              },
              () => {
                this._auth.api.is_loader(false);
              }
            );
            if(this.current_user.neighborhood == null){
              this.openInput()
            }
            this.loginForm.reset();
            this.__init();
            this.windowRef.confirmationResult = null;
          },
          (err) => {
            this._auth.api.is_loader(false);

            if (err.error.ask_resend_link && err.error.message) {
              this._auth.api.toast.info(err.error.message);
              this.object_login.ask_resend_link = true;
            } else if (err.error.message) {
              this._auth.api.toast.error(err.error.message);
            }
            console.log(err);
          }
        );
      },
      error: (error) => {
        // Gère les erreurs
        console.error('Une erreur est survenue :', error);
      },
      complete: () => {
        // Gère la fin de l'observable
        console.log('Observable terminé');
      }
    });
    } else {
      this._auth.api.toast.info("Code incorrect");
    }
  }

  // verifyLoginCode() {
  //   this._auth.api.is_loader(true);
  //   // alert(this.phoneForm.value.code)
  //   console.log(this.phoneForm.value);
  //   this.windowRef.confirmationResult
  //     .confirm(this.phoneForm.value.code)
  //     .then(
  //       (result) => {
  //         const user = result.user;
  //         // alert(JSON.stringify(user))
  //         console.log(result.user)
  //         this.object_register.ask_resend_link = false;
  //         this._auth
  //           .login({
  //             ...user.providerData[0],
  //             uid: user.uid,
  //             ref_parent: localStorage.getItem("key_parain_code"),
  //           })
  //           .subscribe(
  //             (data) => {
  //               this.current_user = this._auth.currentUserValue
  //               if (data.message) {
  //                 this._auth.api.toast.success(data.message);
  //               }
  //               this.router.navigate([this.returnUrl]).then(
  //                 () => {
  //                   this._auth.api.is_loader(false);
  //                 },
  //                 () => {
  //                   this._auth.api.is_loader(false);
  //                 }
  //               );
  //               if(this.current_user.neighborhood == null){
  //                 this.openInput()
  //               }
  //               this.loginForm.reset();
  //               this.__init();
  //               this.windowRef.confirmationResult = null;
  //             },
  //             (err) => {
  //               this._auth.api.is_loader(false);

  //               if (err.error.ask_resend_link && err.error.message) {
  //                 this._auth.api.toast.info(err.error.message);
  //                 this.object_login.ask_resend_link = true;
  //               } else if (err.error.message) {
  //                 this._auth.api.toast.error(err.error.message);
  //               }
  //               console.log(err);
  //             }
  //           );
  //       },
  //       (error) => {
  //         this._auth.api.is_loader(false);
  //         console.log(error, "Incorrect code entered?");
  //       }
  //     )
  //     .catch((error) => {
  //       this._auth.api.is_loader(false);
  //       console.log(error, "Incorrect code entered?");
  //     });
  // }

  goto_provider() {
    this.type_login = "list_view";
    this.__init();
  }

  open_form(type) {
    switch (type) {
      case "phone":
        this.type_login = "phone";
        break;

      default:
        break;
    }
  }

  close_menu($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loginSubmit(){
    console.log(this.phoneForm)
    this._auth.login({

    })
    .subscribe((res: any) =>{
      console.log(res)
    })
  }

  estNumeroMTNCameroun(numero: string) {
    // Expression régulière pour vérifier si le numéro est au format camerounais
    const regexCameroun = /^(\+?237|00237)?[2368]\d{7,8}$/;

    // Vérifie si le texte correspond à l'expression régulière
    if (!regexCameroun.test(numero)) {
        return false; // Le numéro n'est pas au format camerounais
    }

    // Plages de numéros attribuées à MTN Cameroun
    const plagesMTN = [
        '650', '651', '652', '653', '654',
        '670', '671', '672', '673', '674', '675', '676', '677', '678', '679',
        '680', '681', '682', '683', '684', '685', '686', '687', '688', '689'
    ];

    // Extrait les 3 premiers chiffres du numéro
    let indicatif = numero.slice(-9, -6);

    // Vérifie si les 3 premiers chiffres correspondent à ceux de MTN
    return plagesMTN.includes(indicatif);
}
}
