<div mat-dialog-content>
    <div class="content_view_wha">
        <div class='bottom-20'>
            <img width="100%" height="280px" src="assets/wallpaperbetter.jpg" alt="">
        </div>

        <p *ngIf="data.key==='home'" class="text-left" [innerHTML]="'popup.full_text'|translate"></p>

        <p *ngIf="data.key==='login_wha'" class="text-left" [innerHTML]="'popup.full_text_wha'|translate"></p>

        <a *ngIf="data.key==='home'" (click)="$event.stopPropagation();" routerLink="/nouveau-jeu" class="close-menu button button-margins button-full button-m round-small shadow-huge bg-red4-light">
      {{'popup.btn_text'|translate}}
    </a>

        <a *ngIf="data.key==='login_wha'" href="https://api.WhatsApp.com/send?phone=+237665272727&text=quiz" target="_blanc" class="button button-round bg-whatsapp button button-s button-icon button-full">
            <i class="fab fa-whatsapp"></i> via WhatsApp
        </a>
    </div>
</div>
