import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { CustomService } from '../../_services/custom.service';

@Component({
  selector: 'app-ville',
  templateUrl: './ville.component.html',
  styleUrls: ['./ville.component.scss']
})
export class VilleComponent implements OnInit {

  region = [
    {traduction: "ad", value: "adamoua"},
    {traduction: "ce", value: "centre"},
    {traduction: "ea", value: "est"},
    {traduction: "en", value: "extreme-nord"},
    {traduction: "li", value: "littoral"},
    {traduction: "no", value: "nord"},
    {traduction: "nw", value: "nord-ouest"},
    {traduction: "we", value: "ouest"},
    {traduction: "su", value: "sud"},
    {traduction: "sw", value: "sud-ouest"},
  ];
  updateForm: FormGroup
  isLoad: boolean = false
  get full_name(){
    return this.updateForm.get("full_name");
  }

  get neighborhood(){
    return this.updateForm.get("neighborhood");
  }

  constructor(
    private auth: AuthenticationService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    this.updateForm = this.fb.group({
      full_name: fb.control('', [Validators.required, Validators.minLength(4)]),
      player_name: fb.control(''),
      neighborhood: fb.control('', Validators.required),
      lang: fb.control('fr'),
      is_whatsapp: fb.control(false),
      is_sound: fb.control(false)
    })
  }

  close(){
    this.dialog.closeAll()
  }

  update(){
    this.isLoad = true
    const log = this.updateForm.value;
    log.player_name = log.full_name
    this.auth.api.is_loader(true);
    if (this.updateForm.valid) {
      this.auth.profile(log, true)
        .subscribe((data) => {
          this.auth.api.toast.success(data.message);
          this.auth.api.is_loader(false);
          this.isLoad = false
          this.close()
        }, err => {
          this.auth.api.is_loader(false);
          this.isLoad = false
          if (err.error.message)
            this.auth.api.toast.error(err.error.message);
          console.log(err);
        });
    }
  }

  ngOnInit(): void {
  }

}
