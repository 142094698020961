<form class="container" [formGroup]="updateForm" (ngSubmit)="update()">
    <div class="">
        <div class="center" style="margin: 10px 0px;">
            <img src="assets/Smart-Quiz-2.png" alt="" class="" style="width: 300px;">
        </div>
    </div>
    <div class="">
        <label for="">{{'complete_account.full_name.label'|translate}}</label>
        <input type="text" formControlName="full_name">
        <div class="alert alert-danger" *ngIf="full_name.invalid && (full_name.dirty && full_name.touched)">
            <div *ngIf="full_name.errors.required">
                Champ réquis
            </div>
            <div *ngIf="full_name.errors.minlength">
                Entre au moins 4 caractères
            </div>
        </div>
    </div>
    <!-- <div class="">
        <label for="">Nom complet</label>
        <input type="text" formControlName="full_name">
    </div> -->
    <div class="">
        <label for="">{{'complete_account.select.label'|translate}}</label>
        <select name="" id="" formControlName="neighborhood">
          <option selected disabled hidden>{{'complete_account.select.placeholder'|translate}}</option>
        <option *ngFor="let item of region" [value]="item.value">{{'complete_account.lang.' + item.traduction|translate}}</option>
      </select>
        <div class="alert alert-danger" *ngIf="neighborhood.invalid && (neighborhood.dirty && neighborhood.touched)">
            <div *ngIf="neighborhood.errors.required">
                Champ réquis
            </div>
        </div>
    </div>
    <button *ngIf="!isLoad" class="button button-full button-m round-small bg-yellow2-light bottom-" style="color: #1a1a1a !important;" type="submit" [disabled]="updateForm.invalid">
      {{'views.save'|translate}}
    </button>
    <div *ngIf="isLoad" class="spinner">
        <svg viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </div>
</form>