import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  SERVER_URL = environment.custom_api_url
  constructor(
    private HttpClient: HttpClient
  ) { }

  general(){
    const API_URL = this.SERVER_URL + '?getRange'
    return this.HttpClient.get(
      API_URL
    )
  }

  week(region: string){
    const API_URL = this.SERVER_URL + '?getRange&borh=' + region
    return this.HttpClient.get(
      API_URL
    )
  }

  logOrange(phone: string){
    const API_URL = this.SERVER_URL + '?addOrange&phone=' + phone
    return this.HttpClient.get(
      API_URL
    )
  }

  getTokenNumber(phone: string){
    const API_URL = "https://smart-quizz.zen-apps.com/back-end/apiverification.php?verification=" + phone
    return this.HttpClient.get(
      API_URL
    )
  }

  sendCodeOTP(phone: string, code: string){
    const sms = "Y'ello, voici le numéro à taper pour la vérification : " + code;
    const API_URL = "https://advancedsmssending.zen-apps.com/sendsms.php?senderid=SmartQuizz&sms="+code+"&mobiles=" + phone.replace("+", "");
    return this.HttpClient.get(
      API_URL
    )
  }
}
